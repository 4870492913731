<template>
  <div class="addressedit">
    <van-nav-bar :title="titleText" left-arrow @click-left="onClickLeft" />
    <van-address-edit
      :area-list="areaList"
      :address-info="addressInfo"
      show-delete
      show-set-default
      show-search-result
      :search-result="searchResult"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
      @change-detail="onChangeDetail"
    >
      <template #default>
        <div class="address_shibie">
          <van-field
            v-model="addressStr"
            rows="3"
            type="textarea"
            placeholder="请输入地址信息 可自动识别"
          />
          <van-button size="small" plain type="primary" hairline @click="parseAddress">识别</van-button>
        </div>
      </template>
    </van-address-edit>
  </div>
</template>
<script>
import areaList from "@/components/area.js";
import SmartAddress from 'react-smart-address';
export default {
  name: "AddressList",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      chosenAddressId: "",
      areaList: areaList,
      searchResult: [],
      titleText: "",
      id: 0,
      addressInfo: {}, // 默认地址信息
      addressStr: "", // 识别的地址字符串
      shareid: 0,
      merchid: 0
    };
  },
  mounted() {

    this.id = parseInt(this.$route.query.id);
    this.titleText = this.$route.query.title;
    
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    
    this.$parent.getmerchname(this.merchid, this.titleText);

    this.init();
  },
  methods: {
    init() {
      if (this.id) {
        this.getAddress();
      }
    },
    // 获取地址信息
    getAddress() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.post(
        _that.$store.state.domain + 'web/address/get_address',
        _that.$qs.stringify({
          id: _that.id
        })
      ).then(response => {
        console.log(response);
        if (response && response.data && response.data.code == 100000) {
          if (response.data.data) {
            _that.addressInfo = response.data.data;
          }
        }else{
          _that.$notify({
            type: 'warning',
            message: response && response.data ? response.data.msg : ''
          })
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 返回上一步
    onClickLeft() {
      this.$router.go(-1);
    },
    // 添加收货地址
    onSave(content) {
      let _that = null;
      _that = this;
      const DK_UID = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "" ;
      _that.$axios.defaults.headers.common['Authorization'] = DK_UID;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.post(
        _that.$store.state.domain + 'web/address/add',
        _that.$qs.stringify({
          content: content,
          id: _that.id
        })
      ).then(response => {
          console.log(response);
          if (response && response.data && response.data.code == 100000) {
            _that.$notify({
              type: 'success',
              message: response.data.msg ? response.data.msg : "保存成功"
            })
            setTimeout(() => {
              _that.$router.go(-1);
            }, 2000);
          }else {
            _that.$toast(response.data.msg ? response.data.msg : "保存失败");
          }
        }).catch(error => {
          console.log(error);
        })
      console.log(content);
    },
    // 删除收货地址
    onDelete() {
      let _that = null;
      _that = this;
      const DK_UID = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "" ;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios.post(
        _that.$store.state.domain + 'web/address/delete',
        _that.$qs.stringify({
          id: _that.id,
          jwt: DK_UID
        })
      ).then(response => {
        console.log(response);
        if (response.data.code == 100000) {
          _that.$notify({
            type: 'success',
            message: response.data.msg ? response.data.msg : '删除成功'
          })
          setTimeout(()=>{
            _that.$router.go(-1);
          },2000)
        }
      }).catch(error => {
        console.log(error);
      })
    },
    onChangeDetail(){
      console.log("选择收货地址");
    },
    parseAddress(){
      let address = null;
      address = SmartAddress.smart(this.addressStr);
      let areacode = "";
      let name = "";
      if (address.name) {
        name = address.name;
      }else{
        name = address.residueaddress;
      }
      if (address.countyCode) {
        areacode = address.countyCode;
      }else{
        if (address.cityCode) {
          areacode = address.cityCode;
        }else{
          if (address.provinceCode) {
            areacode = address.provinceCode;
          }
        }
      }
      
      this.addressInfo = {
        name:	name,
        tel:	address.mobile,
        province:	address.province,
        city:	address.city,
        county:	address.county,
        addressDetail:	address.address,
        areaCode:	areacode,
        isDefault:	false
      }
      console.log(address);
    }
  }
};
</script>
<style lang="less">
.addressedit{
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  background: #ededed;
  .van-nav-bar{
    .van-nav-bar__left{
      .van-icon{
        color: #333;
      }
    }
  }
  .van-cell{
    .van-cell__value{
      flex: 1;
    }
  }
  .address_shibie{
    margin-top: 10px;
    overflow: hidden;
    background-color: #fff;
    padding: 10px 0;
    text-align: right;
    .van-button{
      margin-top: 10px;
      margin-right: 16px;
      padding: 0 20px;
    }
    .van-button--hairline::after{
      border-radius: 10px;
    }
  }
  .van-switch--on{
    background-color: #ee0a24;
  }
  .van-address-edit__default {
    .van-cell__title {
      flex: 1;
      text-align: left;
    }
  }
}
</style>
